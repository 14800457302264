import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/breadcrumbs"
import { HomeLink, ServicesLink } from "../../components/links"
import ImageCard from "../../components/image-card"
import Alert from "../../components/alert"

const ServicesPage = ({ data }) => {
  const images = {
    bracesMetal: getImage(data.bracesMetal),
    bracesCeramic: getImage(data.bracesCeramic),
    invisalign: getImage(data.invisalign),
    suresmile: getImage(data.suresmile),
  }
  return (
    <>
      <SEO title="Services offered" />

      <Breadcrumbs>
        <HomeLink />
        <ServicesLink />
      </Breadcrumbs>

      <article className="prose dark:prose-invert max-w-none prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
        <h2>Services offered</h2>
        <Alert type="lightbulb">
          <span className="text-brand-primary dark:text-brand-secondary font-bold">
            <i>A beautiful smile is always in style.</i>
          </span>
        </Alert>
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div>
            <h3>Orthodontics</h3>
            <ul>
              <li>Metal braces</li>
              <li>Ceramic clear braces</li>
              <li>Clear aligners (Invisalign, SureSmile)</li>
              <li>Interceptive orthodontics for children</li>
              <li>Alignment setup for implants, crown, and bridge</li>
              <li>Coordinated orthognathic jaw surgery</li>
              <li>Accelerated orthodontics</li>
              <li>Impacted teeth and wisdom teeth</li>
              <li>Uprigting molars</li>
              <li>TMJ disorder (TMD)</li>
              <li>Trauma</li>
            </ul>
          </div>
          <div>
            <h3>Removable and fixed appliances</h3>
            <ul>
              <li>Clear essix retainer</li>
              <li>Hawley retainer</li>
              <li>Fixed retainer</li>
              <li>Nightguard and mouthgard</li>
              <li>Space maintainer</li>
              <li>Carriere motion appliance</li>
              <li>Orthodontic headgear or face frame</li>
              <li>Transpalatal bar</li>
              <li>Rapid maxillary expansion</li>
              <li>Frankel appliance</li>
            </ul>
          </div>
          <div>
            <h3>Tooth whitening</h3>
            <ul>
              <li>Zoom! Professional in-office whitening</li>
              <li>At-home whitening kits</li>
            </ul>
          </div>
          <div>
            <h3>Imaging</h3>
            <ul>
              <li>3-D intraoral scan</li>
              <li>Panoramic x-ray</li>
              <li>Lateral cephalogram</li>
              <li>Periapical x-ray</li>
              <li>Occlusal x-ray</li>
            </ul>
          </div>
        </div>
        <div className="divider"></div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <ImageCard
            image={
              <GatsbyImage
                image={images.bracesMetal}
                alt="Photo of metal braces on a teeth model"
                className="rounded-2xl"
              />
            }
            name="Metal braces"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.bracesCeramic}
                alt="Photo of ceramic braces on a teeth model"
                className="rounded-2xl"
              ></GatsbyImage>
            }
            name="Ceramic braces"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.invisalign}
                alt="Photo of Invisalign aligners on a teeth model"
                className="rounded-2xl"
              ></GatsbyImage>
            }
            name="Invisalign aligners"
          ></ImageCard>
          <ImageCard
            image={
              <GatsbyImage
                image={images.suresmile}
                alt="Photo of SureSmile aligners on a teeth model"
                className="rounded-2xl"
              ></GatsbyImage>
            }
            name="SureSmile aligners"
          ></ImageCard>
        </div>
      </article>
    </>
  )
}

export default ServicesPage

export const query = graphql`
  query {
    bracesMetal: file(relativePath: { eq: "services/braces-metal.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    bracesCeramic: file(relativePath: { eq: "services/braces-ceramic.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    invisalign: file(relativePath: { eq: "services/invisalign.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    suresmile: file(relativePath: { eq: "services/suresmile.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
